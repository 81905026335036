import React, { useState } from "react";
import Heading from "../../../components/ui/Heading";
import { Modal, Table } from "react-bootstrap";
import ConnectionCard from "./ConnectionCard";
import { BsFacebook } from "react-icons/bs";
import DDFilterByAppUrl from "../../../components/ui/DDFilterByAppUrl";
import { Get, Put } from "../../../api/method";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import { toast } from "react-toastify";
import Paginations from "../../../components/ui/Paginations";
import { AdminUsers } from "../../../components/User";
import CheckSwitch from "../../../components/ui/CheckSwitch";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import ConnectionEdit from "./ConnectionEdit";
import { useContextData } from "../../../api/context";

const ConnectionFilter = () => {
  const value = useContextData();
  const { mutateAsync } = Put();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [consultantId, setConsultantId] = useState(0);
  const [branch, setBranch] = useState(
    value.user.branchId !== 1 ? value.user.branchId : 0
  );
  const [modalOpen, setModalOpen] = useState(false);

  const { data, error, isLoading, isError, refetch } = Get(
    "key",
    `MetaManage?page=${currentPage}&pagesize=${dataPerPage}&branchid=${branch}`
  );

  const onSubmit = (formData) => {
    mutateAsync({
      path: "/MetaManage",
      formData: formData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
  };

  const pageData = data?.data;
  const connections = pageData?.consultantConnections;
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Heading text="Connections" />

        <div className="d-flex">
          {AdminUsers() && value.user.branchId === 1 && (
            <DDFilterByAppUrl
              label=""
              placeholder="Select Branch"
              url="event/BranchApi/Index"
              defaultValue={branch}
              action={setBranch}
              className="mb-24px me-2"
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : isError ? (
        <ErrorMessage message={error.message} />
      ) : (
        <>
          <div>
            {AdminUsers() && (
              <ConnectionCard
                Icon={BsFacebook}
                iconColor="#0866FF"
                text="Facebook"
                value={true}
                actionActive={() =>
                  onSubmit({
                    connectionId: pageData.connectionId,
                    isActive: !pageData.isActive,
                  })
                }
              />
            )}
          </div>

          <div className="card">
            <Table responsive>
              <thead>
                <tr>
                  <th>Consultant Name</th>
                  <th>Connected Page</th>
                  <th>Enable Lead Source </th>
                </tr>
              </thead>
              <tbody>
                {connections?.models?.map((item, i) => (
                  <tr key={i}>
                    <td className="align-middle">
                      <span className="fs-14px">{item.consultantName}</span>
                    </td>
                    <td>
                      {item?.metaPageItems?.map((page, j) => (
                        <ConnectionCard
                          key={j}
                          Icon={BsFacebook}
                          iconColor="#0866FF"
                          text={page.pageName}
                          connectionType={page.isOwn ? "Own" : "Assigned"}
                          value={page.isActive}
                          actionActive={() =>
                            onSubmit({
                              id: page.id,
                              consultantId: item.consultantId,
                              isActive: !page.isActive,
                              isOwn: page.isOwn,
                            })
                          }
                        />
                      ))}
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <ButtonPrimary
                          text="Assign Page"
                          action={() => {
                            setConsultantId(item.consultantId);
                            setModalOpen(true);
                          }}
                          className="me-2"
                        />
                        {item.connectionId && (
                          <CheckSwitch
                            register={() => {}}
                            defaultValue={item.isActive}
                            action={() =>
                              onSubmit({
                                connectionId: item.connectionId,
                                isActive: !item.isActive,
                              })
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Paginations
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataPerPage={dataPerPage}
              setDataPerPage={setDataPerPage}
              totalData={connections?.totalEntity}
            />
          </div>
        </>
      )}

      <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Body>
          <ConnectionEdit
            id={consultantId}
            refetch={refetch}
            action={() => setModalOpen(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectionFilter;
